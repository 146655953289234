import React from 'react';
import CoronaVeiligheidsregels from '../images/CoronaVeiligheidsregels.jpg';

const CoronaSafetyRules = ({ imageSrc }) => {
  return (
    <div id="section8" className="container-fluid col-sm-12">
      <h2>Corona Veiligheidsregels</h2>
      <img
        id="CoronaVeiligheidsregels"
        alt="coronaveiligheidsmaatregelen"
        align="middle"
        className="modalpicture"
        src={CoronaVeiligheidsregels}
      />
    </div>
  );
};

export default CoronaSafetyRules;
