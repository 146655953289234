import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const M3matrixModal = ({ showModal, handleClose }) => {
  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>M3 matrix</Modal.Title>
      </Modal.Header>
      <Modal.Body id="modalbody">
        <p>De M3 is een intense anti-age behandeling en stelt de behoefte van een facelifting voor jaren uit.</p>
        <p>De M3 combineert de kracht van de nieuwste technologieën op basis van no needle meso , radiofrequentie en led therapie met actieve en medische ingrediënten.</p>
        <p>M3 zorgt voor een intense huidverjonging door het aanmaken van nieuw collageen en elastine, de fibroblasten worden terug op actief gezet.</p>
        <p>De led therapie gaat uw huid energie geven en de groei van uw huidweefsel bevorderen.</p>
        <p>Voor meer gedetailleerde info kan u mij contacteren. We starten dan met een gratis intakegesprek.</p>
        <p>Relaxerende behandeling, inclusief massage.</p>
        <p class="price">110,00 EUR</p>	
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default M3matrixModal;