import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import AppNavbar from './othercomponents/Navbar';
import MyCarousel from './othercomponents/MyCarousel'
import CoronaSafetyRules from './infotext/coronaveiligheidsregels';
import IntroductionSection from './infotext/introductie';
import Socialmediainfo from './infotext/socialmedia';
import LocatieInfo from "./infotext/locatieinfo"
import ContactInfo from './infotext/contactinfo';
import GelaatsverzorgingTile from './tiles/GelaatsverzorgingTile';
import PeelingTile from './tiles/peelingTile';
import LedtherapieTile from './tiles/ledtherapieTile'
import M3MatrixTile from './tiles/m3MatrixTile';
import PedicureTile from './tiles/pedicureTile';
import CoolliftTile from './tiles/cooliftTile';
import OntharingTile from './tiles/ontharingTile'
import VervenTile from './tiles/vervenTile';
import CadeauTipTile from './tiles/cadeautipTile';
import LaserontharingTile from './tiles/laserontharingTile'
import ProductenTile from './tiles/productenTile';
import Footer from './othercomponents/footer';

const App = () => {
  return (
    <div>
      {/* head html */} 
      <head>
        <meta http-equiv="cache-control" content="max-age=0" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="expires" content="Tue, 01 Jan 1980 1:00:00 GMT" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        <title>Fiebeau</title>
      </head>

      {/* body html */} 
      <body data-spy="scroll" data-target=".navbar" data-offset="100">     
          {/* navbar section */} 
          <div>
              <AppNavbar />
          </div>

          {/* Container bootstrap row 2 */} 
          <div id="section1" class="container-fluid">
            {/* Introductie sectie */} 
            <IntroductionSection />
          </div>

          {/* Container bootstrap row 2 */} 
          <div className="container">
            <div className="row">
              {/* Producten section */} 
              <div id="section2" className="container-fluid col-sm-6">
                <h1 class="col-sm-12" id="producten" >Producten</h1>
                <div className="tileswrapper">
                  <ProductenTile />
                </div>
              </div>
              {/* Carousel foto section */} 
              <div id="section9" class="container-fluid col-sm-6">
                <h1 class="col-sm-12" id="sfeerbeelden" style={{ marginTop: '2vh' }}>Sfeerbeelden</h1>
                <div class="tileswrapper">
                    <MyCarousel />
                </div>
              </div>
            </div>
          </div>  

          {/* Container bootstrap row 2 */} 
          <div className="container">
            <div className="row">
              {/* Behandelingen section */} 
              <div div id="section3" class="container-fluid">
                <div className="row">
                  <h1 class="sectiontitle">Behandelingen</h1>
                    <GelaatsverzorgingTile />
                    <PeelingTile />
                    <LedtherapieTile />
                    <M3MatrixTile />
                    <PedicureTile />
                    <CoolliftTile />
                    <OntharingTile />
                    <VervenTile />
                </div>
              </div>
            </div>         
          </div>  

          {/* Container bootstrap row 3 */} 
          <div className="container">
            <div className="row">
              {/* cadeautip section */}
              <div id="section5" class="container-fluid col-sm-6">
                <h1>Cadeautip</h1>
                <CadeauTipTile />
              </div>
              {/* Laserontharing section */}
              <div id="section6" class="container-fluid col-sm-6">
                <h1>Definitieve laserontharing</h1>
                <LaserontharingTile />
              </div>
            </div>
          </div>

          {/* Container bootstrap row 4 */} 
          <div className="container">
            <div className="row">
              {/* corona veiligheid sectie */}
              <CoronaSafetyRules />
            </div>
          </div>

          {/* Container bootstrap row 5 */} 
          <div className="container">
            <div className="row">
              {/* Locatie sectie */}
              <LocatieInfo />
              
              {/* Contact sectie */}
              <div class="contact col-sm-12 col-md-6" id="section7">
              <ContactInfo />  

              {/* social media sectie */}
              <div class="contact col-sm-6 col-md-6" style={{ marginBottom: '2vh' }}>
                <Socialmediainfo />
              </div>
            </div>
          </div>

        </div>
        <Footer />
      </body>
    </div>
  );
};

export default App;