import React, { useState } from 'react';
import cadeautip from '../images/cadeautip.jpg';
import CadeauTipModal from '../modals/cadeautipModal';

const CadeauTipTile = ({ image, title, modalId }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <div className="tiles col-sm-6">
          <img
            src={cadeautip}
            className="img-rounded"
            alt="cadeautip"
            onClick={handleShow}
          />
          <div className="caption">
            <span className="tiletext" onClick={handleShow}>
              Cadeautip
            </span>
            <span style={{ float: 'right' }}>
              <button className="btn btn-default btn-xs" onClick={handleShow}>
                Meer Info
              </button>
            </span>
          </div>
      <CadeauTipModal showModal={showModal} handleClose={handleClose} />
    </div>
  );
};

export default CadeauTipTile;
