import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import wenkbrauwen from '../images/wenkbrauwen.jpg'

const VervenModal = ({ showModal, handleClose }) => {
  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Verven van de wenkbrauwen</Modal.Title>
      </Modal.Header>
      <Modal.Body id="modalbody">
        <p>Gekleurde wenkbrauwen dragen bij tot een mooie uitstraling van u gelaat.</p>
        <p>De intensiteit kan u zelf bepalen.</p>
        <p>Probeer het en u zal duidelijk het verschil van u gelaatsuitdrukking waarnemen.</p>
        <p>Verven wenkbrauwen</p>
        <p class="price">14,00 EUR</p>
        <img id="wenkbrauwen" align="middle" class="modalpicture" src={wenkbrauwen} alt="wenkbrauwen" />

      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default VervenModal;