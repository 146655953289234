import React from 'react';

const ContactSection = () => {
  return (
    <div className="contact col-sm-12 col-md-6" id="section7" style={{ marginBottom: '5vh' }}>
      <h2>Locatie</h2>
      <iframe
        title="Locatie"
        style={{ border: 'none', width: '100%', height: '280px' }}
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4125.628507482934!2d3.6384906466505527!3d50.95415890396773!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c30d4d38e479a5%3A0xf8d11c399146ca8a!2sSteenweg%20105%2C%209810%20Nazareth!5e0!3m2!1snl!2sbe!4v1644762507422!5m2!1snl!2sbe"
      ></iframe>
      <span>
        <i className="fas fa-envelope"></i> Steenweg 105 a, 9810 Nazareth-Eke
      </span>
    </div>
  );
};

export default ContactSection;
