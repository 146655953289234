import React from 'react';
import logofiebeau from '../images/logofiebeau.png';

const IntroductionSection = ({ logoSrc, logoAlt }) => {
    return (
        <div className="row">
            <div class="image col-sm-6 col-md-4">
                <img id="candles" class="img-rounded" src={logofiebeau} alt=""/>
                <p><b><big>Gratis en vrijblijvend intakegesprek!</big></b></p>
            </div>
              <div class="textfield col-sm-6 col-md-8" id="infotext">
                <p>Schoonheidsinstituut Fiebeau  biedt een brede waaier behandelingen aan, met een sterke focus op gezichtsbehandelingen met huidverbetering en huidverjonging als resultaat.</p> 
                <p>Schittekatte Britt  heet je van harte welkom en staat je bij met professioneel advies en begeleiding bij de zoektocht naar de juiste behandeling voor uw huid.</p>
                <p>In het rustgevend kader kan u ook terecht voor klassieke verzorgingen waaronder pedicure,  ontharing met hars en definitief , verven van wenkbrauwen.</p>
                <p>Ik hoop u snel te mogen verwelkomen.</p>
            </div>
        </div>
    );
  };
  
  export default IntroductionSection;
