import React, { useState } from 'react';
import ProductModal from '../modals/productenModal';
import merken from '../images/merken.png'; 

const ProductenTile = ({ image, title, modalId }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <div className="tiles col-sm-12">
          <img
            src={merken}
            className="img-rounded"
            alt="merken"
            onClick={handleShow}
          />
          <div className="caption">
            <span className="tiletext" onClick={handleShow}>
                Mesoestetic en Dermedics
            </span>
            <span style={{ float: 'right' }}>
              <button className="btn btn-default btn-xs" onClick={handleShow} style={{ height: '4.5vh', fontSize: '2vh' }}>
                Meer Info
              </button>
            </span>
          </div>
      <ProductModal showModal={showModal} handleClose={handleClose} />
    </div>
  );
};

export default ProductenTile;
