import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const CadeauTipModal = ({ showModal, handleClose }) => {
  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Cadeautip</Modal.Title>
      </Modal.Header>
      <Modal.Body id="modalbody">
        <p>Verras een vriend, vriendin of familielid met één van onze cadeautips.</p>
		    <p>Cadeaubon, juweeltjes en producten.</p>
		    <p>Leuk om te geven maar uiteraard ook leuk om te krijgen.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CadeauTipModal;