import React, { useState } from 'react';
import M3matrix from '../images/M3matrix.jpg';
import M3matrixModal from '../modals/m3MatrixModal';

const M3MatrixTile = ({ image, title, modalId }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <div className="tiles col-sm-6 col-md-3">
          <img
            src={M3matrix}
            className="img-rounded"
            alt="M3matrix"
            onClick={handleShow}
          />
          <div className="caption">
            <span className="tiletext" onClick={handleShow}>
              M3 matrix
            </span>
            <span style={{ float: 'right' }}>
              <button className="btn btn-default btn-xs" onClick={handleShow}>
                Meer Info
              </button>
            </span>
          </div>
      <M3matrixModal showModal={showModal} handleClose={handleClose} />
    </div>
  );
};

export default M3MatrixTile;
