import React from 'react';
import { Carousel } from 'react-bootstrap';
import Sfeerbeeld1 from '../images/Sfeerbeeld1.jpeg';
import Sfeerbeeld2 from '../images/Sfeerbeeld2.jpeg';
import Sfeerbeeld3 from '../images/Sfeerbeeld3.jpeg';
import Sfeerbeeld4 from '../images/Sfeerbeeld4.jpeg';
import Sfeerbeeld5 from '../images/Sfeerbeeld5.jpeg';
import Sfeerbeeld6 from '../images/Sfeerbeeld6.jpeg';
import Sfeerbeeld7 from '../images/Sfeerbeeld7.jpeg';
import Sfeerbeeld8 from '../images/Sfeerbeeld8.jpeg';
import Sfeerbeeld9 from '../images/Sfeerbeeld9.jpeg';
import Sfeerbeeld10 from '../images/Sfeerbeeld10.jpeg';
import Sfeerbeeld11 from '../images/Sfeerbeeld11.jpeg';

const MyCarousel = () => {

  return (
    <Carousel>
      <Carousel.Item>
        <img
          className="img-rounded"
          src={Sfeerbeeld1}
          alt="Sfeerbeeld1"
          crop="scale"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="img-rounded"
          src={Sfeerbeeld2}
          alt="Sfeerbeeld2"
          crop="scale"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="img-rounded"
          src={Sfeerbeeld3}
          alt="Sfeerbeeld3"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="img-rounded"
          src={Sfeerbeeld4}
          alt="Sfeerbeeld4"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="img-rounded"
          src={Sfeerbeeld5}
          alt="Sfeerbeeld5"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="img-rounded"
          src={Sfeerbeeld6}
          alt="Sfeerbeeld6"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="img-rounded"
          src={Sfeerbeeld7}
          alt="Sfeerbeeld7"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="img-rounded"
          src={Sfeerbeeld8}
          alt="Sfeerbeeld8"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="img-rounded"
          src={Sfeerbeeld9}
          alt="Sfeerbeeld9"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="img-rounded"
          src={Sfeerbeeld10}
          alt="Sfeerbeeld10"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="img-rounded"
          src={Sfeerbeeld11}
          alt="Sfeerbeeld11"
        />
      </Carousel.Item>
    </Carousel>
  );
};

export default MyCarousel;