import React, { useState } from 'react';
import ledtherapie from '../images/ledtherapie.jpg';
import LedtherapieModal from '../modals/ledtherapieModal';

const LedtherapieTile = ({ image, title, modalId }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <div className="tiles col-sm-6 col-md-3">
          <img
            src={ledtherapie}
            className="img-rounded"
            alt="ledtherapie"
            onClick={handleShow}
          />
          <div className="caption">
            <span className="tiletext" onClick={handleShow}>
              Led-therapie
            </span>
            <span style={{ float: 'right' }}>
              <button className="btn btn-default btn-xs" onClick={handleShow}>
                Meer Info
              </button>
            </span>
          </div>
      <LedtherapieModal showModal={showModal} handleClose={handleClose} />
    </div>
  );
};

export default LedtherapieTile;
