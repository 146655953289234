import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const gelaatsverzorgingModal = ({ showModal, handleClose }) => {
  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Gelaatsverzoring</Modal.Title>
      </Modal.Header>
      <Modal.Body id="modalbody">
        <p><b>Relax treatment ( 60 min )</b></p>
        <p>Relaxerende behandeling met de medische producten van Dermedics.</p>
        <p>Er wordt rekening gehouden met de behoeften van de huid.</p>
        <p>Inclusief massage, crèmemasker en epilatie van de wenkbrauwen.</p>
                  
        <p class="price">80,00 EUR</p>
                
        <p><b>Luxe relax treatment ( 70 min ) </b></p>
        <p>Intense anti-age behandeling en me-time met de medische producten van Dermedics.</p>
        <p>Er wordt rekening gehouden met de behoeften van de huid.</p>
        <p>Inclusief ampoule, massage, vliesmasker en epilatie van de wenkbrauwen.</p>
                  
        <p class="price">90,00 EUR</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default gelaatsverzorgingModal;