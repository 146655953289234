import React, { useState } from 'react';
import medischepeeling2 from '../images/medischepeeling2.jpg';
import PeelingModal from '../modals/peelingModal';

const PeelingTile = ({ image, title, modalId }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <div className="tiles col-sm-6 col-md-3">
          <img
            src={medischepeeling2}
            className="img-rounded"
            alt="peeling"
            onClick={handleShow}
          />
          <div className="caption">
            <span className="tiletext" onClick={handleShow}>
              Chemische peeling/led-therapie
            </span>
            <span style={{ float: 'right' }}>
              <button className="btn btn-default btn-xs" onClick={handleShow}>
                Meer Info
              </button>
            </span>
          </div>
      <PeelingModal showModal={showModal} handleClose={handleClose} />
    </div>
  );
};

export default PeelingTile;
