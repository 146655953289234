import React, { useState } from 'react';
import laserontharing from '../images/laserontharing.jpg';
import LaserontharingModal from '../modals/laserontharingModal';

const LaserontharingTile = ({ image, title, modalId }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <div className="tiles col-sm-6">
          <img
            src={laserontharing}
            className="img-rounded"
            alt="laserontharing"
            onClick={handleShow}
          />
          <div className="caption">
            <span className="tiletext" onClick={handleShow}>
              Definitieve laserontharing
            </span>
            <span style={{ float: 'right' }}>
              <button className="btn btn-default btn-xs" onClick={handleShow}>
                Meer Info
              </button>
            </span>
          </div>
      <LaserontharingModal showModal={showModal} handleClose={handleClose} />
    </div>
  );
};

export default LaserontharingTile;
