import React, { useState } from 'react';
import gelaatsverzorging from '../images/gelaatsverzorging.jpg';
import GelaatsverzorgingModal from '../modals/gelaatsverzorgingModal';

const GelaatsverzorgingTile = ({ image, title, modalId }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
      <div className="tiles col-sm-6 col-md-3">
          <img
            src={gelaatsverzorging}
            className="img-rounded"
            alt="gelaatsverzorging"
            onClick={handleShow}
          />
          <div className="caption">
            <span className="tiletext" onClick={handleShow}>
              Gelaatsverzorging
            </span>
            <span style={{ float: 'right' }}>
              <button className="btn btn-default btn-xs" onClick={handleShow}>
                Meer Info
              </button>
            </span>
          </div>

      <GelaatsverzorgingModal showModal={showModal} handleClose={handleClose} />
      </div>
  );
};

export default GelaatsverzorgingTile;
