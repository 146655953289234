import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const OntharingModal = ({ showModal, handleClose }) => {
  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Ontharing met hars</Modal.Title>
      </Modal.Header>
      <Modal.Body id="modalbody">
        <p>Gladde benen, haarloze oksels, een verzorgde bikinilijn geven je zelfvertrouwen een boost.</p>
        <p>Mooi gestylde wenkbrauwen ondersteunen je uitstraling. Weg met alle overbodige haartjes.</p>
        <p>Wenkbrauwen</p>
        <p class="price">13,00 EUR</p>
        <p>Bovenlip</p>
        <p class="price">11,00 EUR</p>
        <p>Kin</p>
        <p class="price">10,00 EUR</p>
        <p>Onderbenen en knie</p>
        <p class="price">26,00 EUR</p>
        <p>Volledige benen</p>
        <p class="price">38,00 EUR</p>
        <p>Bikinilijn</p>
        <p class="price">18,00 EUR</p>
        <p>Oksels</p>
        <p class="price">16,00 EUR</p>
        <p>Armen</p>
        <p class="price">20,00 EUR</p>
        <p>Borst of rug</p>
        <p class="price">35,00 EUR</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OntharingModal;