import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ProductModal = ({ showModal, handleClose }) => {
  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Medische huidverzorging op het hoogste niveau.</Modal.Title>
      </Modal.Header>
      <Modal.Body id="modalbody">
        <p><b>Dermedics en Mesoestetic</b>, specialist in medische behandelingen.</p>
		    <p>De revolutionaire producten van Mesoestetic en Dermedics op basis van hoogwaardige actieve bestanddelen bieden snelle en doeltreffende oplossingen voor alle behoeften van uw huid.</p>
		    <p>De producten zijn gericht op veilige en echte resultaten.</p>
		    <p>De airless verpakking biedt volledige bescherming tegen bacteriën, licht en lucht en behoudt de versheid van de producten.</p>
		    <p>Bovendien zijn de producten niet getest op dieren.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProductModal;