import React from 'react';
import FacebookIcon from '../images/FacebookIcon.png';

const Socialmediainfo = () => {
  return (
    <div>
        <h2>Social</h2>
        <a class="fb"  href="https://www.facebook.com/schoonheidsinstituut.fiebeau"> 
        <img id="fb" alt="facebookicon" style={{ verticalAlign: 'middle', marginRight: '1vw' }} src={FacebookIcon}/> 
        </a>
        <a
        href="https://www.instagram.com/schoonheidsinstituut_fiebeau/"
        className="fa fa-instagram"
        style={{ fontSize: '4vh', verticalAlign: 'middle' }}
        aria-label="Visit our Instagram profile"
        ></a>
    </div>

    );
};

export default Socialmediainfo;