import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const cooliftModal = ({ showModal, handleClose }) => {
  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Coolift Beautygun</Modal.Title>
      </Modal.Header>
      <Modal.Body id="modalbody">
        <p>De behandeling is een anti-agingbehandeling en zorgt voor intense huidverjonging, lift je huid en is momenteel de meest spectaculaire behandeling van het moment.</p>
		    <p>Met de coolift beautygun gaan we onder hoge druk hyaluronzuur, peptiden, glycerine en tarweproteïnen loslaten op de huid. Hierbij werken we met een krachtige CO2 straal onder zeer lage temperatuur ( -20 graden Celsius ). Hierdoor gaan we de fibroblasten in de huid terug activeren om nieuw collageen aan te maken.</p>
        <p>Een behandeling geeft onmiddellijk resultaat, de huid wordt gladder, oppervlakkige rimpels verdwijnen en diepere rimpels vervagen.</p>
        <p>De behandeling wordt gecombineerd met led-therapie. De behandeling kan eenmalig of als onderhoud toegepast worden, maar ook in kuurvorm om je collageenaanmaak intensief te stimuleren.</p>
        <p>Voor de behandelingen in kuurvorm hebben we promo tarieven en krijgt u bovendien de coolift dag en nachtcreme cadeau.</p>
		    <p class="price">95,00 EUR</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default cooliftModal;