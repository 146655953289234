import React from 'react';

const ContactInfo = () => {
  return (
    <div>
        <h2>Contact</h2>   
        <span id="contact" className="fas fa-phone" title="Telephone Icon" style={{ display: 'inline-block',  margin: '5px', marginRight: '5px' }}></span>
        <span >0498/10.77.79</span>
        <br/>
        <i className="fas fa-envelope" style={{ margin: '5px',marginRight: '5px' }}></i>
        <a href="mailto:info@fiebeau.be">info@fiebeau.be</a>
        <p id="par">Enkel op afspraak - zondag gesloten</p>
        <p>BTW nummer: BE0676406932</p>
    </div>
  );
};

export default ContactInfo;
