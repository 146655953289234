import React, { useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap';

const AppNavbar = () => {
  useEffect(() => {
    // Create and append the script tag
    const script = document.createElement('script');
    script.src = 'https://widget.treatwell.be/common/venue-menu/javascript/widget-button.js?v1';
    script.async = true;
    document.head.appendChild(script);

    // Create and append the stylesheet link
    const link = document.createElement('link');
    link.type = 'text/css';
    link.href = 'https://widget.treatwell.be/common/venue-menu/css/widget-button.css';
    link.rel = 'stylesheet';
    link.media = 'screen';
    document.head.appendChild(link);

    // Clean up the script and link when the component is unmounted
    return () => {
      document.head.removeChild(script);
      document.head.removeChild(link);
    };
  }, []);

  return (
    <Navbar bg="light" expand="lg" className="fixed-top custom-navbar" defaultExpanded={false}  style={{ lineHeight: "2vh"}}>
      <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ marginLeft: 'auto', marginRight: "2vw", fontSize: '2vh'}} />
      <Navbar.Collapse id="basic-navbar-nav" style={{ background: 'white' }}>
        <Nav className="ml-auto d-flex align-items-center">
          <Nav.Link href="#section1" style={{ marginLeft: '10px' }}>Info</Nav.Link>
          <Nav.Link href="#section2" style={{ marginLeft: '10px' }}>Producten</Nav.Link>
          <Nav.Link href="#section3" style={{ marginLeft: '10px' }}>Behandelingen</Nav.Link>
          <Nav.Link href="#section9" style={{ marginLeft: '10px' }}>Sfeerbeelden</Nav.Link>
          <Nav.Link href="#section5" style={{ marginLeft: '10px' }}>Cadeautip</Nav.Link>
          <Nav.Link href="#section6" style={{ marginLeft: '10px' }}>Definitieve Ontharing</Nav.Link>
          <Nav.Link href="#section8" style={{ marginLeft: '10px' }}>CoronaMaatregelen</Nav.Link>
          <Nav.Link href="#section7" style={{ marginLeft: '10px' }}>Contact</Nav.Link>
          <button
            className="btn col-xs-12"
            style={{ marginLeft: '1vw', marginRight: '1vw', marginBottom: '2vh', marginTop: '1.5vh' }}
            onClick={() => {
              // Check if wahanda is defined before using it
              if (window.wahanda) {
                window.wahanda.openOnlineBookingWidget('https://widget.treatwell.be/salon/382713/menu/');
              } else {
                console.error('wahanda is not defined');
              }
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Boek nu!
          </button>
          
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default AppNavbar;

