import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const LedtherapieModal = ({ showModal, handleClose }) => {
  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Led therapie</Modal.Title>
      </Modal.Header>
      <Modal.Body id="modalbody">
        <p><b>Led therapie</b></p>
        <p>Het apparaat met led licht gaat energie geven aan de huid en de groei van uw huidweefsel bevorderen.</p>
        <p>Herstellende werking voor alle huidproblemen zoals acné, ontstekingen, littekens en pigmentatie.</p>
        <p>Werkt bovendien anti-age en zorgt voor een gezonde, frisse uitstraling van uw huid.</p>
        <p>Inclusief reiniging en dagverzorging.</p>
        <p class="price">37,00 EUR</p>
        <p>Tip: combineer deze met de M3 Matrix skin care behandeling.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LedtherapieModal;