import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const LaserontharingModal = ({ showModal, handleClose }) => {
  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Definitieve laserontharing</Modal.Title>
      </Modal.Header>
      <Modal.Body id="modalbody">
        <p>Contacteer ons vrijblijvend voor info en prijzen.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LaserontharingModal;