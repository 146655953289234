// Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer className="fixed-bottom bg-dark text-light text-center p-2">
      &copy; Copyright @ fiebeau - Designed by Databuffalos
    </footer>
  );
};

export default Footer;
